import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DropdownItem = _resolveComponent("DropdownItem");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_DropdownMenu, null, {
    default: _withCtx(() => [_createVNode(_component_DropdownItem, {
      modelValue: $setup.value1,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.value1 = $event),
      options: $setup.option1
    }, null, 8, ["modelValue", "options"]), _createVNode(_component_DropdownItem, {
      modelValue: $setup.value2,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.value2 = $event),
      options: $setup.option2
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  }), _cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "map_nav"
  }, [_createElementVNode("div", {
    class: "map_nav_item"
  })], -1)), _cache[3] || (_cache[3] = _createElementVNode("div", {
    id: "container"
  }, null, -1))], 64);
}