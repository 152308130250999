import "vant/es/dropdown-item/style";
import _DropdownItem from "vant/es/dropdown-item";
import "vant/es/dropdown-menu/style";
import _DropdownMenu from "vant/es/dropdown-menu";
import AMapLoader from '@amap/amap-jsapi-loader';
import { onMounted } from 'vue';
import { ref } from 'vue';
export default {
  name: 'Mobile',
  components: {
    DropdownMenu: _DropdownMenu,
    DropdownItem: _DropdownItem
  },
  setup() {
    let map;
    onMounted(() => {
      AMapLoader.load({
        key: 'eb3cdd67e7988a5b9b39a8d0b13b236c',
        version: '2.0'
      }).then(AMap => {
        map = new AMap.Map('container', {
          zoom: 4.5,
          animateEnable: true,
          center: [112.565026, 37.808929]
        });
        console.log(map.getCenter());
      });
    });
    const value1 = ref(0);
    const value2 = ref('a');
    const option1 = [{
      text: '全部商品',
      value: 0
    }, {
      text: '新款商品',
      value: 1
    }, {
      text: '活动商品',
      value: 2
    }];
    const option2 = [{
      text: '默认排序',
      value: 'a'
    }, {
      text: '好评排序',
      value: 'b'
    }, {
      text: '销量排序',
      value: 'c'
    }];
    return {
      value1,
      value2,
      option1,
      option2
    };
  }
};